@use 'src/styles/common/variables' as *;

.checkbox {
  cursor: pointer;
  position: relative;
}

.checkbox > input {
  height: 1em;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  cursor: pointer;
  content: url('../media/checkbox-false.svg');;
}

.checkbox > input:checked {
  content: url('../media/checkbox-true.svg');
}

.checkbox-label-pair {
  max-width: 80%;
  margin: 20px auto 0;
  font-size: 16px;
  display: inline-flex;
  align-items: flex-start;
}