@use 'src/styles/common/variables' as *;

.required-instructions {
  text-align: left;
  font-size: 14px;
}

.order-information-input {
  min-width: 220px;
  width: 220px;
  max-width: 240px;
  @media (max-width: $max_tablet_portrait) {
    width: 60vw;
    max-width: 473px;
  }
}

.input-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
  row-gap: 1vh;
  margin: 0 2em;
  max-width: calc(100% - 4em);


  @media (max-width: $max_tablet_portrait) {
    margin-bottom: max(3vh, 30px);
    grid-template-columns: 1fr;
  }
}

.address-input {
  box-sizing: border-box;
  width: 100%;
  min-width: 220px;
  max-width: 473px;
  @media (max-width: $max_tablet_portrait) {
    box-sizing: border-box;
    width: 60vw;
  }
}

.full-row {
  grid-column: 1 / -1; 
}
