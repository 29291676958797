@use 'src/styles/common/variables' as *;

.submit-button {
  font-weight: bold;
  padding: 1.25vh 0;
  background-color: $light_gray;
  box-shadow: 1px 2px 4px #0000005A;
  border-radius: 4px;
  color: $white;
  border: none;
  margin: 3vh 0;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  width: 21vw;
  max-width: 80%;
  font-family: $main_text_bold;
  @media (max-width: $max_tablet_portrait) {
    padding: 1vh 0;
    width: 80%;
  }
}

.active-button {
  background-color: $black;
}

.link-button {
  text-decoration: none;
}