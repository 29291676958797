@use 'src/styles/common/variables' as *;

header {
  z-index: 3;
  background: $white;
  width: 100%;
  top: 0;
  box-shadow: 0 3px 6px #00000029;
  padding: 12px 70px 5px;
  @media (max-width: $max_phone_landscape) {
    text-align: center;
    padding: 0 8px 27px;
  }
  min-height: 87px;
}

.go-back-button {
  border: none;
  background-color: $white;
  height: 30px;
  cursor: pointer;
  display: flex;
  margin: 0 0;
  font-family: $main-text;
  padding-left: 6px;
  font-size: 16px;
  img {
    height: 20px;
  }
  @media (max-width: $max_phone_landscape) {
    text-align: center;
    margin: 0 auto;

  }
}

.banner-img {
  height: 40px;
  padding: 10px 10px 3px;

}

html, body {
  overflow-x: hidden;
}

body {
  position: relative
}
