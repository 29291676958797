@use 'src/styles/common/variables' as *;

.order-info-container {
  background: $otavamedia;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding: 20px 0;
  margin: 1em 0;
}

.order-info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 25px;
  @media (max-width: $max_phone_landscape) {
    padding: 0;
  }
}

.package-img {
  max-width: 120px;
  margin-top: 1em;
}

.offer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 1em;
    @media (max-width: $max_phone_landscape) {
      padding: 0;
      align-items: center;
    }
}

.order-info-installments-wrapper {
  max-width: 20vw;
  text-align: left;
  @media (max-width: $max_tablet_portrait) {
    max-width: 90vw;
  }
}

.extraText {
  margin-top: 16px;
  max-width: 80%;
}

.paragraph {
    margin-top: 0px;
}