@use 'src/styles/common/variables' as *;

.error-page-wrapper {
  background: $error_background;
  margin-top: 4vh;
  padding: 4vh 0;
  display: flex;
  flex-direction: column;
  width: 55vw;
  align-items: center;
  box-shadow: 4px 6px 8px #00000029;
  font-family: $main_text;
  @media (max-width: $max_tablet_portrait) {
    width: 100vw;
  }
}

.error-page-wrapper {
  a {
    color: $black;
  }
}

.error-existing-subscription {
  p.error-title {
    font-weight: bold;
    margin: 0px;
  }
}

