@use 'src/styles/common/variables' as *;

.Page {
  text-align: center;
  margin: 2vh 7vw;

  @media (max-width: $max_tablet_portrait) {
    margin: 2vh 4vw;
  }

  @media (max-width: $max_phone_portrait) {
    margin: 1vh $page_side_margin_mobile;
  }
}

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.content-wrapper {
  background: $white;
  display: flex;
  flex-direction: column;
  width: 542px;
  justify-content: center;
  box-shadow: 4px 6px 8px #00000029;
  @media (max-width: $max_phone_landscape) {
    max-width: 100vw;
    width: 100vw;
  }
}

.terms-and-conditions {
  width: 542px;
  padding: 0 16px;
  @media (max-width: $max_phone_landscape) {
    width: 100vw;
  }
  margin-top: 10px;
  position: static;
  bottom: 10px;
}

.appearing-page {
  animation: 1s fade-in;
}

.hidden {
  display: none;
}

.bolder {
  font-weight: bolder;
}

.mt-1 {
  margin-top: 1em;
}

.mb-1 {
  margin-bottom: 1em;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

@keyframes fade-out {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0;
  }
}

