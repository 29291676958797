@use 'src/styles/common/variables' as *;


.order-summary-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
  padding: 0 50px;
  @media (max-width: $max_phone_landscape) {
    grid-template-columns: 1fr;
  }
}

.order-summary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  @media (max-width: $max_phone_landscape) {
    padding: 0;
    margin: auto;
    align-items: center;
    text-align: center;
  }
}

.subscription-info-text {
  font-size: 16px;
  margin-top: 15px;
  font-family: $main_text;
}

.summary-img {
  justify-self: end;
  max-width: 140px;
  object-fit: contain;
}

.summary-info-text {
  margin: 0vh 2vh 0vh 2vh;
}

.component-title {
  margin-bottom: 1vh;
  @media (max-width: $max_phone_portrait) {
    margin-bottom: 0vh;
  }
}

.go-to-service-button-wrapper button {
  margin: 1em 0 2em 0;
}