@use 'src/styles/common/variables' as *;

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
  @media (max-width: $max_tablet_portrait) {
    width: 80vw !important;
  }
}

.popup-window {
  min-width: 30vw;
  max-width: 80vw;
  background: white;
  padding: 2vh 6vw 2vh 3vw;
  overflow: auto;
  max-height: 80vh;
  line-height: 1.5;

  .popup-window-img {
    max-width: min(240px, 40%);
    float: left;
    margin: 0 2em 2em 0;
  }

}

.close {
  cursor: pointer;
  position: absolute;
  background: inherit;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 0;
  top: 0;
  font-size: 24px;
  border-radius: 18px;
  border: none;
}

.read-more-button {
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
  padding: 0;
  font-family: $main-text;
}